// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gp-1001-js": () => import("./../../../src/pages/GP1001.js" /* webpackChunkName: "component---src-pages-gp-1001-js" */),
  "component---src-pages-gp-1002-js": () => import("./../../../src/pages/GP1002.js" /* webpackChunkName: "component---src-pages-gp-1002-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nt-1001-js": () => import("./../../../src/pages/NT1001.js" /* webpackChunkName: "component---src-pages-nt-1001-js" */),
  "component---src-pages-nt-1001-t-js": () => import("./../../../src/pages/NT1001T.js" /* webpackChunkName: "component---src-pages-nt-1001-t-js" */),
  "component---src-pages-nt-1002-js": () => import("./../../../src/pages/NT1002.js" /* webpackChunkName: "component---src-pages-nt-1002-js" */),
  "component---src-pages-sc-1003-js": () => import("./../../../src/pages/SC1003.js" /* webpackChunkName: "component---src-pages-sc-1003-js" */),
  "component---src-pages-sc-1003-t-js": () => import("./../../../src/pages/SC1003T.js" /* webpackChunkName: "component---src-pages-sc-1003-t-js" */)
}

