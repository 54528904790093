import React from 'react'
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react'
import LogRocket from 'logrocket'

LogRocket.init('exsi2o/cating-platform')

// import customTheme from './theme/theme'

const theme = extendTheme({
        components: {
                Input: {
                        baseStyle: {
                                field: {
                                        borderColor: 'yellow.600',
                                },
                        },
                },
        },
})
export const wrapRootElement = ({ element }) => (
        <ChakraProvider>
                <CSSReset />
                {element}
        </ChakraProvider>
)
